// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-engagements-js": () => import("./../../src/pages/engagements.js" /* webpackChunkName: "component---src-pages-engagements-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-request-a-quote-js": () => import("./../../src/pages/request-a-quote.js" /* webpackChunkName: "component---src-pages-request-a-quote-js" */),
  "component---src-pages-weddings-js": () => import("./../../src/pages/weddings.js" /* webpackChunkName: "component---src-pages-weddings-js" */)
}

